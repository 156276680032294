import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AMC_ROLE_TYPE } from 'src/app/core/constants';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { CreateOrgUserData } from 'src/types';

@Component({
  selector: 'app-add-org-user-dialog',
  templateUrl: './add-org-user-dialog.component.html',
  styleUrls: ['./add-org-user-dialog.component.scss'],
})
export class AddOrgUserDialogComponent {
  AmcRoles = AMC_ROLE_TYPE;
  isLoading = false;

  addOrgUserForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', Validators.required),
    phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    role_type: new FormControl('', Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<AddOrgUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private userService: UserProfileService
  ) {}

  onSubmit() {
    if (this.addOrgUserForm.valid) {
      this.isLoading = true;
      const { email, name, phone_number, role_type } = this.addOrgUserForm.value;

      const data: CreateOrgUserData = {
        name: name!,
        phone_number: `+91${phone_number}`,
        email: email!,
        role_type: role_type!,
      };

      this.userService
        .createOrgUser(data)
        .then(() => {
          this.isLoading = false;
          this.dialogRef.close({ success: true });
        })
        .catch(() => (this.isLoading = false));
    }
  }
}
