<mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-primary">Add Member</h1>

  <form [formGroup]="addOrgUserForm">
    <div class="inline-input">
      <label>Name<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline">
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label>Email ID<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline">
        <input matInput type="text" formControlName="email" />
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label>Phone Number<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline">
        <span matPrefix class="pl-3">+91</span>
        <input matInput type="tel" maxlength="10" formControlName="phone_number" />
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label>AMC Type<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline">
        <mat-select placeholder="Amc Role" formControlName="role_type">
          <mat-option *ngFor="let amcRole of AmcRoles" [value]="amcRole">{{ amcRole }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="stepper-nav-container">
    <button
      [disabled]="isLoading"
      (click)="onSubmit()"
      [disabled]="!addOrgUserForm.valid"
      type="button"
      class="save-btn"
    >
      Create
    </button>
  </div>
</mat-dialog-content>
