<div mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-primary">Edit Member</h1>

  <form [formGroup]="editOrgForm">
    <div class="inline-input">
      <label>Name</label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <input matInput [readonly]="true" type="text" formControlName="name" />
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label>AMC Type</label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Amc Role" formControlName="role_type">
          <mat-option *ngFor="let amcRole of AmcRoles" [value]="amcRole">{{ amcRole }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="stepper-nav-container">
    <button
      (click)="onSubmit()"
      [disabled]="!editOrgForm.valid || isLoading"
      type="button"
      class="save-btn"
    >
      Save
    </button>
    <!-- <button type="button" class="del-btn">Remove</button> -->
  </div>
</div>
