<div class="container mx-auto flex flex-col px-3 pb-10 sm:px-0">
  <div class="mt-10">
    <button
      (click)="goBack()"
      type="button"
      class="inline-flex items-center rounded-md bg-secondary px-2 py-1 text-center text-sm font-normal text-white hover:bg-secondary/90 focus:outline-none focus:ring-4 focus:ring-secondary/50 sm:px-4 sm:py-2 sm:text-base sm:font-medium"
    >
      <mat-icon class="mr-1">arrow_back</mat-icon>
      BACK
    </button>
  </div>
  <div class="table-card mt-10 flex flex-col p-4">
    <div class="mb-2 mt-3 flex items-center justify-between px-0 sm:px-4">
      <h3 class="text-lg font-medium sm:text-2xl">My Organization</h3>

      <button
        matTooltipPosition="above"
        aria-label="Add team members button"
        matTooltip="You don't have sufficient permissions to perform this action"
        [matTooltipDisabled]="this.isSuperAdmin()"
        (click)="addOrgUserDialog()"
        type="button"
        class="inline-flex items-center rounded-md bg-lima-500 px-2 py-1 text-center text-sm font-medium text-white hover:bg-lima-600 focus:outline-none focus:ring-4 focus:ring-lima-400 disabled:bg-gray-200 sm:px-5 sm:py-2.5 sm:text-base"
        [disabled]="!this.isSuperAdmin()"
      >
        <mat-icon class="mr-1">add</mat-icon>
        Add Member
      </button>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role_type">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">
          {{ element.role_type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="editable">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            (click)="editOrgUserDialog(element.id)"
            *ngIf="this.isSuperAdmin() && element.role_type !== 'Super Admin'"
            class="mat-icons-outlined cursor-pointer text-lg"
            >edit</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
