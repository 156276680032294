<div mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-secondary">Edit Activity</h1>

  <form [formGroup]="editActivityForm">
    <div class="inline-input">
      <label for="add-project-mounting-type">AMC Type</label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select
          placeholder="Type of Activity"
          id="add-project-offering"
          formControlName="activity_type"
          class="!text-sm sm:!text-base"
        >
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let amcActivity of amcActivityType"
            [value]="amcActivity"
            >{{ amcActivity }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isContractor" class="inline-input">
      <label for="add-project-mounting-type">Contractor<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select
          placeholder="Contractor"
          id="add-project-offering"
          formControlName="contractor_org"
          class="!text-sm sm:!text-base"
        >
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let contractor of contractors"
            [value]="contractor.id"
            >{{ contractor.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label for="add-project-mounting-type">Workman<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select class="!text-sm sm:!text-base" placeholder="Workmen" formControlName="workman">
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let workman of workmanList"
            [value]="workman.id"
            >{{ workman.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label for="add-project-mounting-type">Schedule Date<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <input
          class="!text-sm sm:!text-base"
          placeholder="Date"
          matInput
          formControlName="scheduled_date"
          [matDatepicker]="editScheduledDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="editScheduledDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #editScheduledDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div class="stepper-nav-container">
    <button
      (click)="deleteActivityDialog()"
      [disabled]="isLoading"
      type="button"
      class="delete-btn"
    >
      Delete
    </button>
    <button
      (click)="editActivity()"
      [disabled]="!editActivityForm.valid || isLoading"
      type="button"
      class="save-btn"
    >
      Save & Close
    </button>
  </div>
</div>
