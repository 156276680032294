import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss'],
})
export class OtpVerifyComponent {
  phone_number: string;
  logInFlow = true;
  otp: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.route.params.subscribe(() => {
      this.phone_number = this.route.snapshot.params['phone_number'].split('?')[0];

      if (this.route.snapshot.queryParams['login']) {
        this.logInFlow = this.route.snapshot.queryParams['login'] === '1' ? true : false;
      }
    });
  }

  onOtpChange(event: any) {
    this.otp = event;
  }

  isOTPValid(): boolean {
    return this.otp.length === 4;
  }

  goToUrl() {
    this.router.navigate([this.logInFlow ? '/signup' : '/login']);
  }

  onSubmit() {
    this.authService.logIn(this.phone_number, this.otp);
  }

  sendOtp() {
    if (this.phone_number) {
      this.authService.requestOtp(this.phone_number);
    }
  }
}
