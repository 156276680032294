<div [ngClass]="isOrange() ? 'rounded-full bg-secondary/30 p-5' : 'rounded-full bg-primary/30 p-5'">
  <div
    [ngClass]="
      isOrange()
        ? 'flex items-center justify-center rounded-md bg-secondary p-1'
        : 'flex items-center justify-center rounded-md bg-primary p-1'
    "
  >
    <mat-icon class="text-white" [fontIcon]="icon"></mat-icon>
  </div>
</div>
