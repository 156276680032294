import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AMC_ACTIVITY_CHECKLIST, ORG_TYPE } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { isImageUrl, isVideoUrl } from 'src/app/core/utils';
import { Activity, DialogMode, EditActivityData } from 'src/types';
import { MediaPreviewDialogComponent } from '../media-preview-dialog/media-preview-dialog.component';

@Component({
  selector: 'app-activity-report-dialog',
  templateUrl: './activity-report-dialog.component.html',
  styleUrls: ['./activity-report-dialog.component.scss'],
})
export class ActivityReportDialogComponent implements OnInit {
  editActivityReportForm = new FormGroup({
    contractor_remarks: new FormControl(''),
    business_remarks: new FormControl(''),
  });

  showMoreFiles = false;

  amcActivityChecklist = AMC_ACTIVITY_CHECKLIST;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ActivityReportDialogComponent>,
    private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { activity: Activity; mode: DialogMode },
    private dialog: MatDialog,
    private userProfileService: UserProfileService
  ) {}

  get isBusiness() {
    return this.userProfileService.org.org_type === ORG_TYPE.BUSINESS;
  }

  ngOnInit(): void {
    if (typeof this.data.activity.notes?.contractor_remarks === 'string') {
      this.editActivityReportForm.controls.contractor_remarks.setValue(
        this.data.activity.notes.contractor_remarks
      );
    }
    if (typeof this.data.activity.notes?.business_remarks === 'string') {
      this.editActivityReportForm.controls.business_remarks.setValue(
        this.data.activity.notes.business_remarks
      );
    }

    if (this.isBusiness || this.data.mode === 'view') {
      this.editActivityReportForm.controls.contractor_remarks.disable();
    }

    if (this.data.mode === 'view') {
      this.editActivityReportForm.controls.business_remarks.disable();
    }
  }

  toggleShowMoreFiles() {
    this.showMoreFiles = !this.showMoreFiles;
  }

  getMediaFiles() {
    return this.data.activity.extra_details?.media_files || [];
  }

  get filteredFiles() {
    return this.showMoreFiles ? this.getMediaFiles() : this.getMediaFiles().slice(0, 4);
  }

  isImageUrl = isImageUrl;
  isVideoUrl = isVideoUrl;

  onSubmit(status?: string) {
    let business_remarks, contractor_remarks;

    if (this.isBusiness) {
      business_remarks = this.editActivityReportForm.value.business_remarks || '';
      contractor_remarks = this.data.activity?.notes?.contractor_remarks || '';
    } else {
      business_remarks = this.data.activity?.notes?.business_remarks || '';
      contractor_remarks = this.editActivityReportForm.value.contractor_remarks || '';
    }

    const data: EditActivityData = {
      notes: {
        business_remarks,
        contractor_remarks,
      },
    };

    if (status) {
      data['status'] = status;
    }

    this.isLoading = true;
    this.amcProjectService
      .editActivity(this.data.activity.id, data)
      .then(() => {
        this.isLoading = false;
        this.dialogRef.close({ success: true });
      })
      .catch(() => (this.isLoading = false));
  }

  openMediaPreview(selectedIndex: number = 0): void {
    const dialogRef = this.dialog.open(MediaPreviewDialogComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      data: {
        media: this.data.activity.extra_details?.media_files,
        selectedIndex,
      },
    });

    // dialogRef.afterClosed().subscribe(res => {
    //   if (res && res?.success) {
    //     this.amcService.fetchActivityById(this.amcId).then(resp => {
    //       this.activities = resp;
    //       this.setActivities();
    //     });
    //   }
    // });
  }
}
