import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import {
  AmcOrgUser,
  CreateOrgData,
  CreateOrgUserData,
  OrgUser,
  Organization,
  UserProfileResponse,
} from 'src/types';
import { API_BASE_URL, ORG_AMC_STATUS } from '../constants';

const DefaultProfileData = {
  phone_number: null,
  role_type: null,
  name: null,
  site: null,
  is_email_verified: false,
  id: null,
  is_phone_number_verified: false,
  email: null,
};

const DefaultOrgData: Organization = {
  name: null,
  amc_status: null,
  company_logo: null,
  company_website: null,
  org_type: null,
  id: null,
};

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  user: OrgUser = DefaultProfileData;
  org: Organization = DefaultOrgData;
  orgUsers: Array<AmcOrgUser> = [];

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  openSnackBar(message: string) {
    this.snackBar.open(message, 'DISMISS', {
      duration: 15000,
    });
  }

  reset() {
    this.user = DefaultProfileData;
    this.org = DefaultOrgData;
    this.orgUsers = [];
  }

  fetchOrgUsers() {
    return firstValueFrom(this.http.get<AmcOrgUser[]>(API_BASE_URL + '/installers/amc_org_users'))
      .then(resp => {
        this.orgUsers = resp;
      })
      .catch((e: HttpErrorResponse) => {
        return Promise.reject(e);
      });
  }

  fetchOrgUsersById(id: number) {
    return firstValueFrom(
      this.http.get<AmcOrgUser[]>(API_BASE_URL + `/installers/amc_org_users?org_id=${id}`)
    )
      .then(resp => {
        return resp;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  hasUserOrg() {
    return this.org !== null && this.org.name !== null && this.org.id !== null;
  }

  isOrgApproved() {
    return this.org && this.org.amc_status === ORG_AMC_STATUS.APPROVED;
  }

  getOrgUser(id: number) {
    return this.orgUsers.find(i => i.id == id);
  }

  getWorkman() {
    return this.orgUsers.filter(user => user.role_type == 'Workman');
  }

  getWorkmanByOrgId(id: number) {
    return this.fetchOrgUsersById(id).then(resp =>
      resp.filter(user => user.role_type == 'Workman')
    );
  }

  hasUserProfile() {
    return this.user && this.user.name !== null;
  }

  hasAccess() {
    return this.user && this.user.role_type !== 'Workman';
  }

  clearUserProfileData() {
    this.user = DefaultProfileData;
  }

  isPhoneNumberVerified() {
    return this.user.is_email_verified === true;
  }

  isEmailVerified() {
    return this.user.is_email_verified === true;
  }

  setUserEmail(email: string, verified: boolean = false) {
    this.user.email = email;
    this.user.is_email_verified = verified;
  }
  setUserPhoneNumber(phone: string, verified: boolean = false) {
    this.user.phone_number = phone;
    this.user.is_phone_number_verified = verified;
  }
  setUserName(name: string) {
    this.user.name = name;
  }

  setUserId(id: number) {
    this.user.id = id;
  }

  setUserRoleType(roleType: string) {
    this.user.role_type = roleType;
  }

  setUserSiteName(site: string) {
    this.user.site = site;
  }

  getUserProfile() {
    return firstValueFrom(
      this.http.get<UserProfileResponse>(API_BASE_URL + '/installers/amc_profile/')
    )
      .then(response => {
        return response;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  createOrg(data: CreateOrgData) {
    return firstValueFrom(
      this.http.post<Organization>(API_BASE_URL + '/installers/amc_create_org/', data)
    )
      .then(response => {
        this.setOrg(response);
        return response;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  createOrgUser(data: CreateOrgUserData) {
    return firstValueFrom(this.http.post(API_BASE_URL + '/installers/amc_org_users/', data))
      .then(async () => {
        await this.fetchOrgUsers();
      })
      .catch((e: HttpErrorResponse) => {
        if (e.status === 409) {
          this.openSnackBar(
            'Email or phone number is already registered with an organization, use a different combination.'
          );
        }
        return Promise.reject(e);
      });
  }

  editOrgUserRole(ordId: number, role_type: string) {
    return firstValueFrom(
      this.http.put(API_BASE_URL + '/installers/amc_org_users/' + ordId, { role_type })
    )
      .then(() => {
        this.fetchOrgUsers();
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  setUserProfile(resp: UserProfileResponse) {
    this.setUserEmail(resp.email, resp.is_email_verified);
    this.setUserName(resp.name);
    this.setUserPhoneNumber(resp.phone_number, resp.is_phone_number_verified);
    this.setUserRoleType(resp.role_type);
    this.setUserSiteName(resp.site);
    this.setUserId(resp.id);
    if (resp.organization) this.setOrg(resp.organization);
    return resp.name !== null;
  }

  setOrgName(name: string) {
    this.org.name = name;
  }

  setOrgAmcStatus(status: string) {
    this.org.amc_status = status;
  }

  setOrgCompanyLogo(logo: string) {
    this.org.company_logo = logo;
  }

  setOrgCompanyWebsite(website: string) {
    this.org.company_website = website;
  }

  setOrgType(org: string) {
    this.org.org_type = org;
  }

  setOrgId(id: number) {
    this.org.id = id;
  }

  setOrg(org: Organization) {
    this.org = org;
  }
}
