<div class="h-screen w-screen">
  <nav
    class="fixed start-0 top-0 z-20 w-full border-b border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
  >
    <div class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
      <img src="/assets/GreenCare logo horizontal.png" class="h-11 lg:h-12" alt="GreenCare Logo" />

      <div class="mr-[0.22rem] flex space-x-3 rtl:space-x-reverse md:order-2 md:space-x-0">
        <div class="flex flex-col items-center space-x-2 text-sm sm:flex-row lg:text-base">
          <span> {{ logInFlow ? 'New to GreenCare?' : 'Already have an account?' }}</span>

          <span
            (click)="goToUrl()"
            class="cursor-pointer font-medium text-secondary hover:underline"
          >
            {{ logInFlow ? 'Join Now' : 'Sign In' }}</span
          >
        </div>
      </div>
    </div>
  </nav>
  <div
    class="flex h-full flex-col items-center justify-start space-y-16 px-4 py-20 lg:flex-row lg:justify-center lg:space-y-0 lg:py-0"
  >
    <div class="hidden w-full justify-center lg:flex lg:border-r-2">
      <img
        src="/assets/Greencare services.png"
        class="max-w-xs pr-2 lg:mr-12 lg:max-w-lg lg:pr-10"
        alt="Engineers with solar panel"
      />
    </div>
    <div class="flex h-full w-full items-center justify-center lg:h-auto lg:pt-20">
      <div class="max-w-sm xl:max-w-md">
        <mat-card class="max-w-sm border border-solid">
          <mat-card-content>
            <div class="w-full max-w-md p-6">
              <h3 class="mb-8 w-full text-center font-sans text-xl font-bold text-gray-700">
                Verify your Phone number
              </h3>
              <h4 class="text-md mb-8 w-full text-justify font-sans text-gray-400">
                Enter the verification code you received on +91 {{ phone_number }}
              </h4>
              <ng-otp-input
                class="text-center"
                (onInputChange)="onOtpChange($event)"
                [config]="{
                  length: 4,
                  allowNumbersOnly: true,
                  inputClass: 'focus:!border-[1.5px] focus:!border-secondary'
                }"
              />
              <button
                [disabled]="!isOTPValid()"
                (click)="onSubmit()"
                mat-flat-button
                color="accent"
                class="mt-4 w-full"
              >
                <span class="text-white">Verify</span>
              </button>
              <div class="ml-auto mt-8 text-center text-base">
                Didn't receive the verification code?
                <span
                  (click)="sendOtp()"
                  class="ml-1 cursor-pointer font-medium text-secondary hover:underline"
                  >Resend</span
                >
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
