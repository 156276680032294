import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { UserProfileService } from '../services/user-profile.service';
import { AuthService } from './auth.service';

export const authResolver: ResolveFn<boolean> = (route, state) => {
  const authService = inject(AuthService);
  const userProfile = inject(UserProfileService);
  const router = inject(Router);

  if (authService.isUserLoggedIn()) {
    if (userProfile.hasUserProfile()) {
      router.navigate(['/']);
      return false;
    } else if (state.url !== '/org-signup') {
      authService.logOut();
    }
  }

  return true;
};
