import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fab-icon',
  templateUrl: './fab-icon.component.html',
  styleUrls: ['./fab-icon.component.scss'],
})
export class FabIconComponent {
  @Input() icon: string;
  @Input() color: 'primary' | 'secondary' = 'secondary';

  isOrange() {
    return this.color === 'secondary';
  }
}
