<mat-dialog-content>
  <div class="mx-auto flex max-w-3xl flex-col">
    <h1 class="mb-5 text-xl font-medium text-secondary">Activity Report</h1>

    <div class="project-info-item">
      <h5>Submitted by</h5>
      <span>:</span>
      <span>{{ data.activity.workman?.name }}</span>
    </div>

    <div class="project-info-item">
      <h5>Date of Submission</h5>
      <span>:</span>
      <span>{{ data.activity.completed_date | dateFormatter }}</span>
    </div>

    <div class="project-info-item">
      <h5>Site Photos & Videos</h5>
      <span>:</span>
      <span></span>
    </div>

    <div class="flex items-center justify-center">
      <div class="grid grid-cols-4 gap-5">
        <div *ngFor="let mediaUrl of filteredFiles; index as i">
          <img
            (click)="openMediaPreview(i)"
            *ngIf="isImageUrl(mediaUrl)"
            class="h-40 w-40 cursor-pointer rounded-lg object-cover"
            [src]="mediaUrl"
            alt=""
          />

          <video
            (click)="openMediaPreview(i)"
            type="video/mp4"
            playsinline
            loop
            autoplay
            [muted]="true"
            *ngIf="isVideoUrl(mediaUrl)"
            class="h-40 w-40 cursor-pointer rounded-lg object-cover"
          >
            <source [src]="mediaUrl" />
          </video>
        </div>
      </div>
    </div>
    <div class="more">
      <span *ngIf="getMediaFiles().length > 4" (click)="toggleShowMoreFiles()">{{
        showMoreFiles ? 'Less' : 'More'
      }}</span>
    </div>

    <div class="project-info-item">
      <h5>Site Observations</h5>
      <span>:</span>
      <span></span>
    </div>

    <mat-accordion
      *ngIf="data.activity.extra_details?.activityChecklist"
      class="checklist-headers-align"
      multi
    >
      <mat-expansion-panel *ngFor="let checklist of amcActivityChecklist">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ checklist.name }} </mat-panel-title>
        </mat-expansion-panel-header>

        <ul
          class="px-4"
          *ngFor="
            let item of data.activity.extra_details!.activityChecklist![checklist.key].checklist
          "
        >
          <li class="flex h-16 w-full items-center">
            <span class="font-light text-space-gray"> {{ item.desc }}</span>

            <div
              *ngIf="item.status"
              class="ml-auto flex h-[2.1rem] w-[2.1rem] items-center justify-center rounded-full bg-primary"
            >
              <mat-icon class="text-white">done</mat-icon>
            </div>

            <div
              *ngIf="!item.status"
              class="ml-auto flex h-[2.1rem] w-[2.1rem] items-center justify-center rounded-full bg-space-gray"
            >
              <mat-icon class="text-white">close</mat-icon>
            </div>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <form [formGroup]="editActivityReportForm">
      <div class="project-info-item mt-7">
        <h5>{{ isBusiness ? "Contractor's Remarks" : 'Your Remarks' }}</h5>
        <span>:</span>
        <span></span>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <textarea
          [readonly]="isBusiness || data.mode === 'view' ? true : false"
          matInput
          formControlName="contractor_remarks"
        >
        </textarea>
      </mat-form-field>

      <div *ngIf="isBusiness || data.mode === 'view'">
        <div class="project-info-item">
          <h5>{{ isBusiness ? 'Your Remarks' : "Business's Remarks" }}</h5>
          <span>:</span>
          <span></span>
        </div>
        <mat-form-field class="w-full" appearance="outline">
          <textarea [readonly]="data.mode === 'view'" matInput formControlName="business_remarks">
          </textarea>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="data.mode !== 'view'" class="stepper-nav-container mb-2">
      <button
        *ngIf="!isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit()"
        type="button"
        class="save-btn"
      >
        Save & Close
      </button>
      <button
        *ngIf="isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit('Approved')"
        type="button"
        class="save-btn"
      >
        Approve
        <mat-icon class="ml-1">done</mat-icon>
      </button>

      <button
        *ngIf="isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit('Rejected')"
        type="button"
        class="del-btn"
      >
        Reject
        <mat-icon class="ml-1">close</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
