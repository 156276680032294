import { Pipe, PipeTransform } from '@angular/core';
import { AmcProjectService } from '../services/amc-project.service';

@Pipe({
  name: 'contractorName',
})
export class ContractorNamePipe implements PipeTransform {
  constructor(private amcService: AmcProjectService) {}

  transform(value?: number, ...args: unknown[]): unknown {
    if (!value) return 'None';
    return this.amcService.contractors.find(item => item.id == value)?.name || value;
  }
}
