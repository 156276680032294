import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AddOrgUserDialogComponent } from 'src/app/components/add-org-user-dialog/add-org-user-dialog.component';
import { EditOrgUserDialogComponent } from 'src/app/components/edit-org-user-dialog/edit-org-user-dialog.component';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { AmcOrgUser } from 'src/types';

@Component({
  selector: 'app-my-organization-page',
  templateUrl: './my-organization-page.component.html',
  styleUrls: ['./my-organization-page.component.scss'],
})
export class MyOrganizationPageComponent implements OnInit {
  displayedColumns: string[] = ['name', 'role_type', 'editable'];
  myRole: string | null = null;
  dataSource = new MatTableDataSource<AmcOrgUser>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userProfile: UserProfileService,
    public dialog: MatDialog,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userProfile.fetchOrgUsers().then(() => this.updateTableData());
    this.myRole = this.userProfile.user.role_type;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  updateTableData() {
    this.dataSource = new MatTableDataSource<AmcOrgUser>(this.userProfile.orgUsers);
    this.dataSource.paginator = this.paginator;
  }

  isSuperAdmin() {
    return this.myRole === 'Super Admin';
  }

  editOrgUserDialog(ordId: number): void {
    const dialogRef = this.dialog.open(EditOrgUserDialogComponent, {
      width: '900px',
      data: { ordId },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        setTimeout(() => {
          this.updateTableData();
        }, 1000);
      }
    });
  }

  addOrgUserDialog(): void {
    const dialogRef = this.dialog.open(AddOrgUserDialogComponent, {
      width: '900px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        setTimeout(() => {
          this.updateTableData();
        }, 1000);
      }
    });
  }

  goBack() {
    this.location.back();
  }
}
