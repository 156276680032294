import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AMC_ACTIVITY_TYPE, ORG_TYPE } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { formatAmcDate } from 'src/app/core/utils';
import { AmcOrgUser } from 'src/types';
import { DeleteActivityDialogComponent } from '../delete-activity-dialog/delete-activity-dialog.component';

@Component({
  selector: 'app-edit-activity-dialog',
  templateUrl: './edit-activity-dialog.component.html',
  styleUrls: ['./edit-activity-dialog.component.scss'],
})
export class EditActivityDialogComponent implements OnInit {
  editActivityForm = new FormGroup({
    activity_type: new FormControl('', Validators.required),
    contractor_org: new FormControl(-1, Validators.required),
    scheduled_date: new FormControl<Date | null>(null, Validators.required),
    workman: new FormControl<Number | null>(null),
  });

  amcActivityType = AMC_ACTIVITY_TYPE.slice(0, -2);
  workmanList: AmcOrgUser[] = [];
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<EditActivityDialogComponent>,
    private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { activityId: string; amcId: string },
    private userService: UserProfileService,
    public dialog: MatDialog
  ) {
    this.editActivityForm.controls.contractor_org.valueChanges.subscribe(value => {
      if (typeof value === 'number') {
        this.userService.getWorkmanByOrgId(value).then(resp => {
          this.workmanList = resp;
        });
      } else {
        this.workmanList = [];
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.amcProjectService
      .fetchActivityById(this.data.amcId)
      .then(value => {
        const found = value.find(i => i.id === this.data.activityId);
        if (found) {
          this.editActivityForm.controls.activity_type.setValue(found.activity_type);

          if (
            typeof found.contractor_org === 'number' &&
            this.userService.org.org_type !== ORG_TYPE.CONTRACTOR
          ) {
            this.editActivityForm.controls.contractor_org.setValue(found.contractor_org);
          }

          this.editActivityForm.controls.scheduled_date.setValue(new Date(found.scheduled_date));

          if (typeof found.workman?.id === 'number')
            this.editActivityForm.controls.workman.setValue(found.workman?.id);
        }
      })
      .finally(() => (this.isLoading = false));

    if (
      this.userService.org.org_type === ORG_TYPE.CONTRACTOR &&
      typeof this.userService.org.id === 'number'
    ) {
      this.editActivityForm.controls.contractor_org.setValue(this.userService.org.id);
    }
  }

  get isContractor() {
    return this.userService.hasUserOrg() && this.userService.org.org_type === ORG_TYPE.CONTRACTOR;
  }

  get contractors() {
    return this.amcProjectService.contractors;
  }

  editActivity() {
    this.isLoading = true;
    const data = this.editActivityForm.value as any;
    this.amcProjectService
      .editActivity(this.data.activityId, {
        ...data,
        scheduled_date: formatAmcDate(data.scheduled_date),
      })
      .then(() => {
        this.isLoading = false;
        this.dialogRef.close({ success: true });
      })
      .catch(() => (this.isLoading = false));
  }

  deleteActivityDialog() {
    const dialogRef = this.dialog.open(DeleteActivityDialogComponent, {
      width: '700px',
      data: {
        activityId: this.data.activityId,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.dialogRef.close({ success: true });
      }
    });
  }
}
