<app-navbar [showHome]="false"></app-navbar>

<div class="flex min-h-[calc(100vh-4.5rem)] w-full items-center">
  <div class="hidden basis-6/12 justify-center lg:flex">
    <img src="/assets/Greencare services.png" class="w-3/4 pl-8" alt="Engineers with solar panel" />
  </div>
  <div class="flex h-screen w-full flex-col overflow-auto lg:basis-6/12">
    <div class="mb-8 flex justify-center">
      <mat-card class="mt-28 border border-solid lg:mt-32">
        <mat-card-content class="flex justify-center">
          <div class="w-full p-4 lg:p-6">
            <h3 class="mb-8 w-full text-center font-sans text-xl font-bold text-gray-700">
              Register your Organization
            </h3>
            <form [formGroup]="orgSignUpForm">
              <div class="form-container">
                <div class="inline-input">
                  <label for="org-signup-role"
                    ><span class="font-normal text-gray-500">Register as</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="org_type" id="org-signup-role">
                      <mat-option *ngFor="let org of getOrgType()" [value]="org">{{
                        org
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <label for="org-signup-name"
                  ><span class="font-normal text-gray-500">Name of organization</span
                  ><sup>*</sup></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <input id="org-signup-name" matInput type="text" formControlName="company_name" />
                </mat-form-field>

                <label for="org-signup-org-type"
                  ><span class="font-normal text-gray-500">Type of organization</span></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <mat-select formControlName="type_of_org" id="org-signup-org-type">
                    <mat-option *ngFor="let org of getTypesOfOrg()" [value]="org">{{
                      org
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <label class="mb-1" for="company-phone_number"
                  ><span class="font-normal text-gray-500">Contact No. of organization</span
                  ><sup>*</sup></label
                >
                <mat-form-field class="w-full rounded-md" appearance="outline">
                  <span matPrefix class="pl-3">+91</span>
                  <input
                    maxlength="10"
                    formControlName="org_phone_number"
                    matInput
                    type="text"
                    id="company-phone_number"
                  />
                  <mat-error
                    *ngIf="
                      org_phone_number?.hasError('pattern') &&
                      !org_phone_number?.hasError('required')
                    "
                  >
                    Please enter a valid phone number
                  </mat-error>
                  <mat-error *ngIf="org_phone_number?.hasError('required')">
                    Phone is <strong>required</strong>
                  </mat-error>
                </mat-form-field>

                <label class="mb-1" for="company-email"
                  ><span class="font-normal text-gray-500">Email of organization</span
                  ><sup>*</sup></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <input id="company-email" matInput type="email" formControlName="org_email" />
                  <mat-error
                    *ngIf="org_email?.hasError('email') && !org_email?.hasError('required')"
                  >
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="org_email?.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>

                <label for="org-signup-website"
                  ><span class="font-normal text-gray-500">Website</span></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <input
                    id="org-signup-website"
                    matInput
                    type="text"
                    formControlName="company_website"
                  />
                  <mat-error *ngIf="company_website?.hasError('invalidUrl')">
                    Enter a valid <strong>website</strong>
                  </mat-error>
                </mat-form-field>

                <label for="company_location"
                  ><span class="font-normal text-gray-500">Location of organization</span
                  ><sup>*</sup></label
                >
                <div id="company_location" class="w-full" formGroupName="company_location">
                  <mat-form-field class="w-full" appearance="outline">
                    <input
                      placeholder="Pincode"
                      matInput
                      type="number"
                      maxlength="6"
                      formControlName="pincode"
                    />
                  </mat-form-field>

                  <div *ngIf="this.pincode?.valid" class="flex max-w-xs space-x-5 lg:max-w-xl">
                    <mat-form-field class="w-full" appearance="outline">
                      <input
                        [readonly]="true"
                        placeholder="City"
                        matInput
                        type="text"
                        formControlName="city"
                      />
                    </mat-form-field>

                    <mat-form-field class="w-full" appearance="outline">
                      <input
                        [readonly]="true"
                        placeholder="State"
                        matInput
                        type="text"
                        formControlName="state"
                      />
                    </mat-form-field>
                  </div>

                  <mat-form-field class="w-full" appearance="outline">
                    <textarea
                      placeholder="Enter Full Address (Area and Street)"
                      matInput
                      formControlName="full_address"
                    >
                    </textarea>
                  </mat-form-field>
                </div>

                <label for="org-signup-locations"
                  ><span class="font-normal text-gray-500">Serviceable Location(s)</span></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <mat-chip-grid #chipGrid aria-label="Location selection">
                    <mat-chip-row *ngFor="let city of cities" (removed)="remove(city)">
                      {{ city }}
                      <button matChipRemove [attr.aria-label]="'remove ' + city">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  </mat-chip-grid>
                  <input
                    id="org-signup-locations"
                    placeholder="Add locations"
                    #cityInput
                    formControlName="active_cities"
                    [matChipInputFor]="chipGrid"
                    [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)"
                  />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let city of filteredCities" [value]="city">
                      {{ city }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </form>
            <button
              (click)="onSubmit()"
              [disabled]="!orgSignUpForm.valid"
              type="submit"
              mat-flat-button
              color="accent"
              class="m-auto !flex w-1/2"
            >
              <span class="text-white">Register</span>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
