import { compareDesc, format } from 'date-fns';
import { Project } from 'src/types';

const outputFormat = 'dd MMM yyyy';

export const getAmcTenure = (startDateStr: string, endDateStr: string) => {
  return `${format(parseDate(startDateStr), outputFormat)} - ${format(
    parseDate(endDateStr),
    outputFormat
  )}`;
};

export const parseDate = (dateStr: string) => new Date(dateStr);

export const sortProjectsByEndDate = (a: Project, b: Project) =>
  compareDesc(parseDate(a.end_date), parseDate(b.end_date));

export const formatAmcDate = (dateStr: any) => {
  return format(new Date(dateStr), 'yyyy-MM-dd');
};

export const isImageUrl = (url: string): boolean => {
  const imageExtensions = /\.(jpg|jpeg|png|gif|webp)$/i;
  return imageExtensions.test(url);
};

export const isVideoUrl = (url: string): boolean => {
  const videoExtensions = /\.(mp4|mov|avi|wmv)$/i;
  return videoExtensions.test(url);
};
