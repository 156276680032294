import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ORG_TYPE } from '../constants';
import { UserProfileService } from '../services/user-profile.service';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const userProfileService = inject(UserProfileService);

  // not logged in
  if (!authService.isUserLoggedIn()) {
    router.navigate(['/login']);
    return false;
  }

  // missing org
  if (
    !(userProfileService.hasUserOrg() && userProfileService.hasUserProfile()) &&
    state.url !== '/org-signup'
  ) {
    router.navigate(['/org-signup']);
    return false;
  }

  if (
    (state.url.startsWith('/business') &&
      userProfileService.org.org_type === ORG_TYPE.CONTRACTOR) ||
    (state.url.startsWith('/contractor') &&
      userProfileService.org.org_type === ORG_TYPE.BUSINESS) ||
    (state.url === '/org-signup' && userProfileService.hasUserOrg()) ||
    (state.url === '/registration-complete' && userProfileService.isOrgApproved())
  ) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
