import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject, firstValueFrom } from 'rxjs';
import { LoginResponse, OtpRequestResponse, SignUpData, SignUpResponse } from 'src/types';
import { API_BASE_URL, AUTH_TOKEN_KEY, ORG_TYPE } from '../constants';
import { UserProfileService } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authToken: String = '';
  invalidateAuth = new Subject<boolean>();
  invalidateAuthStream$ = this.invalidateAuth.asObservable();
  processPendingRequestsOnLogin = new Subject<boolean>();
  cleanDataOnLogout = new Subject<boolean>();
  cleanDataOnLogoutStream$ = this.cleanDataOnLogout.asObservable();

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private userProfile: UserProfileService,
    public snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.invalidateAuthStream$.subscribe(invalidateAuth => {
      // Trigger a logout on unauthenticated and unauthorized requests. Not true if user is trying to login.
      if (this.router.url != '/login') {
        this.logOut();
      }
    });
    this.authToken = this.localStorage.retrieve(AUTH_TOKEN_KEY);
    // Handle any change in value of AUTH_TOKEN_KEY - need to set authToken to null if profile request fails
    this.localStorage.observe(AUTH_TOKEN_KEY).subscribe(value => {
      this.authToken = value ? value : null;
    });

    this.cleanDataOnLogoutStream$.subscribe(() => {
      this.userProfile.reset();
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'DISMISS', {
      duration: 15000,
    });
  }

  goToDashboard() {
    // Redirect to login if user profile is missing
    if (!this.userProfile.hasUserProfile()) {
      // Log out?
      this.router.navigate(['/login']);
      return;
    }

    // Redirect to org signup in case of missing org
    if (!this.userProfile.hasUserOrg()) {
      this.router.navigate(['/org-signup']);
      return;
    }

    // Redirect to org registration complete page if org is not approved
    if (!this.userProfile.isOrgApproved()) {
      this.router.navigate(['/registration-complete']);
      return;
    }

    // Redirect Super Admin / Business to business dashboard
    if (
      this.userProfile.org.org_type === ORG_TYPE.BUSINESS ||
      this.userProfile.org.org_type === ORG_TYPE.SUPER_ADMIN
    ) {
      this.router.navigate(['/business']);
    } else if (this.userProfile.org.org_type === ORG_TYPE.CONTRACTOR) {
      this.router.navigate(['/contractor']);
    }
  }

  signUp(data: SignUpData) {
    return firstValueFrom(
      this.http.post<SignUpResponse>(API_BASE_URL + '/installers/amc_signup/', data)
    )
      .then(resp => {
        if (resp.message) {
          Promise.resolve(true);
        }
      })
      .catch(err => {
        if (err.status === 409)
          this.openSnackBar(
            'Phone number or email is already registered. Try another phone number and email, or try signing in.'
          );
        else if (err.status === 500)
          this.openSnackBar('Failed to send OTP! Please try again later.');
        else this.openSnackBar('Server Error! Please try again later.');
        return Promise.reject(err.status);
      });
  }

  requestOtp(phone_number: string) {
    return firstValueFrom(
      this.http.post<OtpRequestResponse>(API_BASE_URL + '/installers/otp_request/', {
        phone_number: `+91${phone_number}`,
      })
    )
      .then(resp => {
        // console.log(resp);
        this.openSnackBar('OTP sent ✅');
        return Promise.resolve(true);
      })
      .catch(error => {
        if (error.status == 404) {
          this.openSnackBar('Phone number is not registered, create an account.');
        } else {
          this.openSnackBar('Failed to send OTP! Please try again later.');
        }
        return Promise.reject(error.status);
      });
  }

  logIn(phone_number: string, otp: string) {
    return firstValueFrom(
      this.http.post<LoginResponse>(API_BASE_URL + '/installers/otp_login/', {
        phone_number: `+91${phone_number}`,
        otp: otp,
      })
    )
      .then(resp => {
        this.setAuthToken(resp.Token);
        const { profile } = resp;
        this.userProfile.setUserProfile(profile);

        this.goToDashboard();
      })
      .catch(error => {
        if (error.status == 401) {
          this.openSnackBar('Please enter the correct OTP');
        }
        return Promise.reject(error.status);
      });
  }

  logOut() {
    this.clearUserCredentials();
    // close any snackbar, if active
    this.snackBar.dismiss();
  }

  setAuthToken(token: String) {
    this.localStorage.store(AUTH_TOKEN_KEY, token);

    // Process pending api requests those needed token
    this.processPendingRequestsOnLogin.next(true);
  }

  getAuthToken(): String {
    return this.authToken;
  }

  isUserLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  clearUserCredentials() {
    this.localStorage.clear(AUTH_TOKEN_KEY);
    //Need to call explicitly for clear profile data. Can't use authService into userProfile service due to circularity
    this.userProfile.clearUserProfileData();
    this.cleanDataOnLogout.next(true);
  }

  requestPasswordResetLink(email: string) {
    firstValueFrom(this.http.post(API_BASE_URL + '/installers/password_reset/request', { email }))
      .then()
      .catch(error => {
        return Promise.reject(error.status);
      });
  }

  updatePassword(userId: string, resetToken: string, password: string) {
    return firstValueFrom(
      this.http.post<{ success: boolean }>(
        API_BASE_URL + '/installers/password_reset/update_password',
        {
          uid: userId,
          reset_token: resetToken,
          password,
        }
      )
    )
      .then(resp => {
        return resp.success;
      })
      .catch(error => {
        return Promise.reject(error.status);
      });
  }
}
