import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  AmcActivityResponse,
  ContractorResponse,
  CreateActivityData,
  CreateAmcProjectBody,
  EditActivityData,
  Project,
} from 'src/types';
import { AuthService } from '../auth/auth.service';
import { API_BASE_URL, ORG_TYPE } from '../constants';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class AmcProjectService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userProfileService: UserProfileService
  ) {
    this.authService.cleanDataOnLogoutStream$.subscribe(() => {
      this.reset();
    });
  }

  projects: Array<Project> = [];
  contractors: ContractorResponse['data'] = [];

  reset() {
    this.projects = [];
    this.contractors = [];
  }

  fetchProjects() {
    let endpoint = API_BASE_URL + '/monitoring/amc_projects';

    if (
      this.userProfileService.org &&
      this.userProfileService.org.org_type === ORG_TYPE.CONTRACTOR
    ) {
      endpoint += `?contractor_org=${this.userProfileService.org.id}`;
    }

    return firstValueFrom(this.http.get<Project[]>(endpoint))
      .then(response => {
        // process the response to add amc_status
        response = response.map(project => {
          return {
            ...project,
            amc_status:
              !project.start_date && !project.end_date
                ? 'draft'
                : project.amc_status
                  ? 'active'
                  : 'expired',
          };
        });

        this.setProjects(response);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  fetchProjectById(amcId: string) {
    return firstValueFrom(
      this.http.get<{
        amc_project_details: Project;
      }>(API_BASE_URL + '/monitoring/amc_projects/' + amcId)
    )
      .then(response => {
        // process the response to add amc_status
        const project = response.amc_project_details;
        project.amc_status =
          !project.start_date && !project.end_date
            ? 'draft'
            : project.amc_status
              ? 'active'
              : 'expired';
        return project;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  createActivity(amcId: string, data: CreateActivityData) {
    return firstValueFrom(
      this.http.post<any>(API_BASE_URL + '/monitoring/amc_activities/?amc_id=' + amcId, data)
    ).catch(e => {
      return Promise.reject(e);
    });
  }

  editActivity(amcActivityId: string, data: EditActivityData) {
    return firstValueFrom(
      this.http.patch<any>(API_BASE_URL + '/monitoring/amc_activities/' + amcActivityId, data)
    ).catch(e => {
      return Promise.reject(e);
    });
  }

  fetchActivityById(amcId: string) {
    return firstValueFrom(
      this.http.get<AmcActivityResponse>(
        API_BASE_URL + '/monitoring/amc_activities/?amc_id=' + amcId
      )
    )
      .then(response => response.activities)
      .catch(e => {
        return Promise.reject(e);
      });
  }

  fetchPincodeDetails(pincode: string) {
    return firstValueFrom(
      this.http.get<{
        pincode: string;
        city: string;
        state: string;
      }>(API_BASE_URL + '/pincode_lookup/' + pincode)
    )
      .then(resp1 => {
        return this.fetchDiscom(resp1.state).then(({ electricity_providers }) => {
          return {
            ...resp1,
            electricity_providers,
          };
        });
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  fetchDiscom(state: string) {
    return firstValueFrom(
      this.http.get<{
        electricity_providers: string[];
      }>(API_BASE_URL + '/electricity_providers/' + state)
    )
      .then(response => {
        return response;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  setProjects(projects: Array<Project>) {
    this.projects = projects;
  }

  getProjects() {
    return this.projects;
  }

  fetchContractors() {
    return firstValueFrom(
      this.http.get<ContractorResponse>(API_BASE_URL + '/installers/get_contractors')
    )
      .then(response => {
        this.contractors = response.data;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  createAmcProject(data: CreateAmcProjectBody) {
    return firstValueFrom(this.http.post<any>(API_BASE_URL + '/monitoring/amc_projects/', data))
      .then(() => {
        this.fetchProjects();
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  editAmcProject(projectId: string, data: CreateAmcProjectBody) {
    return firstValueFrom(
      this.http.patch<any>(API_BASE_URL + '/monitoring/amc_projects/' + projectId, data)
    )
      .then(() => {
        this.fetchProjects();
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  getContractorName(id: number) {
    return this.contractors.find(item => item.id === id)?.name;
  }
}
