<nav
  class="fixed top-0 z-50 flex h-[4.5rem] w-full items-center justify-center bg-white p-4 shadow-sm"
>
  <div class="container flex items-center justify-between">
    <div class="flex items-center space-x-8">
      <img src="/assets/GreenCare logo horizontal.png" class="h-11 lg:h-12" alt="GreenCare Logo" />
    </div>

    <div class="flex items-center space-x-14">
      <div *ngIf="showHome" (click)="goToHome()" class="group flex h-[4.5rem] flex-col">
        <button class="h-full w-10 pt-2">
          <mat-icon
            class="material-icons-outlined text-[#777373] group-hover:text-secondary group-active:text-secondary"
            >home</mat-icon
          >
        </button>
        <span class="h-1 w-full rounded-t-md group-active:bg-secondary"></span>
      </div>

      <button
        id="dropdownUserButton"
        data-dropdown-toggle="dropdown"
        class="flex items-center rounded-md border border-transparent p-2 text-[#777373] hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
      >
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <span class="ml-1 mr-1.5 text-xs font-semibold lg:text-sm">{{ getUserName() }}</span>
        <mat-icon class="material-icons-outlined">arrow_drop_down</mat-icon>
      </button>
    </div>
    <!-- Dropdown menu -->
    <div
      id="dropdown"
      class="z-50 hidden w-[12.5rem] divide-y divide-gray-100 rounded-lg border border-gray-200 bg-white shadow-dropdown"
    >
      <div class="mx-2 px-4 py-4 text-sm text-[#777373]">
        <div class="font-semibold uppercase text-gray-500">{{ getUserName() }}</div>
        <div *ngIf="isOrgApproved">{{ getOrgName() }}</div>
        <div *ngIf="isOrgApproved">{{ getRole() }}</div>
      </div>

      <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownUserButton">
        <li>
          <span
            *ngIf="isOrgApproved"
            (click)="goToMyOrganization()"
            class="mx-2 block cursor-pointer rounded-md px-4 py-4 text-secondary hover:bg-gray-100"
            >My Organization</span
          >

          <span
            *ngIf="!isOrgApproved"
            class="mx-2 block cursor-pointer rounded-md px-4 py-4 text-secondary hover:bg-gray-100"
            >No organization yet</span
          >
        </li>
      </ul>
      <div class="py-2">
        <a
          href="#"
          (click)="signOut()"
          class="mx-2 flex items-center rounded-md px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-400"
        >
          <mat-icon class="material-icons-outlined pb-2 text-lg leading-normal">logout</mat-icon>
          <span class="text-sm">Sign Out</span>
        </a>
      </div>
    </div>
  </div>
</nav>
