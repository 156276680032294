import { LiveAnnouncer } from '@angular/cdk/a11y';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  API_BASE_URL,
  ORG_AMC_STATUS,
  ORG_DETAIL_TYPE_OF_ORG,
  ORG_TYPE,
} from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { websiteValidator } from './website.validator';

@Component({
  selector: 'app-org-signup-page',
  templateUrl: './org-signup-page.component.html',
  styleUrls: ['./org-signup-page.component.scss'],
})
export class OrgSignupPageComponent implements OnInit {
  separatorKeysCodes: number[] = [];

  filteredCities: string[] = [];
  cities: string[] = [];
  allCities: string[] = [];
  yearsInSolarOptions = [
    { value: '0', viewValue: 'No experience yet' },
    { value: '<1', viewValue: 'Less than 1 year' },
    { value: '1-2', viewValue: '1 to 2 years' },
    { value: '2-5', viewValue: '2 to 5 years' },
    { value: '>5', viewValue: 'More than 5 years' },
  ];

  orgSignUpForm = new FormGroup({
    org_type: new FormControl('', [
      Validators.required,
      Validators.pattern(`^(${Object.keys(ORG_TYPE).join('|')})$`),
    ]),
    company_name: new FormControl('', [Validators.required]),
    type_of_org: new FormControl('', [
      Validators.pattern(`^(${Object.keys(ORG_DETAIL_TYPE_OF_ORG).join('|')})$`),
    ]),
    org_phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    org_email: new FormControl('', [Validators.required, Validators.email]),
    company_website: new FormControl('', websiteValidator),
    company_location: new FormGroup({
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')]),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
    }),
    active_cities: new FormControl(''),
  });

  @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);

  constructor(
    private http: HttpClient,
    private userProfile: UserProfileService,
    private router: Router,
    private amcProjectService: AmcProjectService,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) {
    this.pincode?.valueChanges.subscribe(value => {
      if (this.pincode?.valid) {
        this.amcProjectService.fetchPincodeDetails(value as string).then(({ city, state }) => {
          this.city?.setValue(city);
          this.state?.setValue(state);
        });
      }
    });
  }

  ngOnInit(): void {
    // initFlowbite();
    this.active_cities?.valueChanges.subscribe(searchInp => {
      if (!searchInp) {
        this.filteredCities = this.allCities.slice();
      } else {
        this._filter(searchInp).then(resp => {
          this.filteredCities = resp;
        });
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'DISMISS', {
      duration: 15000,
    });
  }

  searchCity(searchInp: string) {
    return firstValueFrom(
      this.http.get<
        Array<{
          pincode: string;
          city: string;
          state: string;
        }>
      >(API_BASE_URL + '/city_search/' + searchInp || ' ')
    )
      .then(resp => resp.map(item => item.city).slice(0, 30))
      .catch(error => {
        return Promise.reject(error.status);
      });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.cities.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.active_cities?.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.cities.indexOf(fruit);

    if (index >= 0) {
      this.cities.splice(index, 1);

      this.announcer.announce(`Removed ${fruit}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.cities.includes(event.option.viewValue)) this.cities.push(event.option.viewValue);
    this.cityInput.nativeElement.value = '';
    this.active_cities?.setValue(null);
  }

  private _filter(value: string): Promise<string[]> {
    return this.searchCity(value.toLowerCase()).then(resp => resp);
  }

  get active_cities() {
    return this.orgSignUpForm.get('active_cities');
  }

  get company_name() {
    return this.orgSignUpForm.get('company_name');
  }

  get org_type() {
    return this.orgSignUpForm.get('org_type');
  }

  get company_location() {
    return this.orgSignUpForm.controls['company_location'];
  }

  get pincode() {
    return this.company_location.get('pincode');
  }

  get city() {
    return this.company_location.get('city');
  }

  get state() {
    return this.company_location.get('state');
  }

  get org_phone_number() {
    return this.orgSignUpForm.get('org_phone_number');
  }

  get org_email() {
    return this.orgSignUpForm.get('org_email');
  }

  get company_website() {
    return this.orgSignUpForm.get('company_website');
  }

  getUserName() {
    return this.userProfile.user.name;
  }

  getOrgType() {
    return Object.keys(ORG_TYPE).slice(0, 2);
  }

  getTypesOfOrg() {
    return Object.keys(ORG_DETAIL_TYPE_OF_ORG);
  }

  onSubmit() {
    this.userProfile
      .createOrg({
        ...(this.orgSignUpForm.value as any),
        active_cities: this.cities,
      })
      .then(resp => {
        if (resp.amc_status === ORG_AMC_STATUS.IN_REVIEW) {
          this.router.navigate(['/registration-complete']);
        } else if (resp.org_type === ORG_TYPE.BUSINESS) {
          this.router.navigate(['/business']);
        } else if (resp.org_type === ORG_TYPE.CONTRACTOR) {
          this.router.navigate(['/contractor']);
        }
      })
      .catch(err => {
        this.openSnackBar('Server Error! Please try again later.');
      });
  }

  signOut() {
    this.authService.logOut();
  }
}
