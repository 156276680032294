import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
})
export class SignupPageComponent {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  goToLogIn() {
    this.router.navigate(['/login']);
  }

  signupForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
  });
  hide = true;

  onSubmit() {
    this.authService
      .signUp({
        ...this.signupForm.value,
        phone_number: `+91${this.signupForm.value.phone_number}`,
      })
      .then(_ =>
        this.router.navigate([`/verify-otp/${this.phone_number?.value}`], {
          queryParams: {
            login: 0,
          },
        })
      );
  }

  get name() {
    return this.signupForm.get('name');
  }
  get email() {
    return this.signupForm.get('email');
  }
  get phone_number() {
    return this.signupForm.get('phone_number');
  }
}
