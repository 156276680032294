import { environment } from 'src/environments/environment';
import { ActivityChecklist } from 'src/types';

export const AUTH_TOKEN_KEY = environment.AUTH_TOKEN_KEY;
export const API_BASE_URL = environment.API_BASE_URL; // 'http://127.0.0.1:8000/api/v1';

export const ORG_TYPE = {
  BUSINESS: 'BUSINESS',
  CONTRACTOR: 'CONTRACTOR',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const ORG_AMC_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  IN_REVIEW: 'In Review',
};

export const AMC_ACTIVITY_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
};

export const ORG_DETAIL_TYPE_OF_ORG = {
  INSTALLER: 'Installer',
  FINANCIAL: 'Financial',
  CONSULTANT: 'Consultant',
  INVESTOR: 'Investor',
};

export const AMC_TYPE = [
  'Cleaning only',
  'Preventive AMC',
  'Preventive with cleaning',
  'Comprehensive with cleaning',
];

export const AMC_ACTIVITY_TYPE = [
  'Cleaning only',
  'Preventive AMC',
  'Comprehensive AMC',
  'Incidental',
  'Inspection',
  'Renewal',
  'Installation of Hardware',
  'Preventive with cleaning',
  'Comprehensive with cleaning',
];

export const AMC_ROLE_TYPE = ['Business Manager', 'AMC Manager', 'Project Manager', 'Workman'];

export const AMC_ACTIVITY_CHECKLIST: Array<{
  key: keyof ActivityChecklist;
  name: string;
}> = [
  {
    name: 'Plumbing',
    key: 'plumbing',
  },
  {
    name: 'PV Checklist',
    key: 'pvChecklist',
  },
  {
    name: 'Inverter Checklist',
    key: 'inverterChecklist',
  },
  {
    name: 'ACDB/DCDB',
    key: 'acdbdcdb',
  },
  {
    name: 'Monitoring Device',
    key: 'monitoringDevice',
  },
];
