<div class="table-card mt-10 flex flex-col p-4">
  <div class="mb-2 mt-3 flex items-center justify-between px-4">
    <h3 class="text-lg font-medium md:text-xl xl:text-2xl">Projects</h3>
    <button
      matTooltipPosition="above"
      aria-label="Add project button"
      matTooltip="You don't have sufficient permissions to perform this action"
      [matTooltipDisabled]="this.hasAccess"
      (click)="openDialog('0ms', '0ms')"
      type="button"
      class="inline-flex items-center rounded-full bg-primary px-4 py-2 text-center text-base font-medium text-white hover:bg-primary/90 focus:outline-none focus:ring-4 focus:ring-primary/50 disabled:bg-gray-200"
      [disabled]="!this.hasAccess"
    >
      <mat-icon class="mr-1">add</mat-icon>
      Project
    </button>
  </div>

  <div class="px-2 pb-4 pt-8">
    <form
      [formGroup]="filterForm"
      class="grid grid-cols-3 gap-x-5 sm:grid-cols-[2fr_1fr_1fr_1fr] sm:gap-x-10"
    >
      <mat-form-field floatLabel="auto" class="col-span-3 sm:col-span-1">
        <mat-label class="text-sm sm:text-base">Search by Project Name, City or State</mat-label>
        <input matInput type="text" formControlName="search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Project Owner</mat-label>

        <mat-select formControlName="project_owner">
          <mat-option value="all" class="!text-sm sm:!text-base">All</mat-option>
          <mat-option
            *ngFor="let projectOwner of projectOwnerFilterOptions"
            [value]="projectOwner.value"
            class="!text-sm sm:!text-base"
            >{{ projectOwner.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Contractor</mat-label>
        <mat-select formControlName="contractor">
          <mat-option [value]="-1" class="!text-sm sm:!text-base">All</mat-option>
          <mat-option
            *ngFor="let contactor of contractors"
            [value]="contactor.id"
            class="!text-sm sm:!text-base"
            >{{ contactor.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="all" class="!text-sm sm:!text-base">All</mat-option>
          <mat-option value="active" class="!text-sm sm:!text-base">ACTIVE</mat-option>
          <mat-option value="expired" class="!text-sm sm:!text-base">EXPIRED</mat-option>
          <mat-option value="draft" class="!text-sm sm:!text-base">DRAFT</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="overflow-scroll">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="project_name">
        <th mat-header-cell *matHeaderCellDef class="min-w-32">Project Name</th>
        <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
          {{ element.projectName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="project_owner">
        <th mat-header-cell *matHeaderCellDef class="min-w-32">Project Owner</th>
        <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
          {{ element.projectOwner }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractor">
        <th mat-header-cell *matHeaderCellDef class="min-w-32">Contractor</th>
        <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
          {{ element.contractor ? element.contractor.name : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
          <span
            [ngClass]="{
              'font-semibold uppercase': true,
              'text-leaf': element.status == 'active',
              'text-red-600': element.status == 'expired',
              'text-slate-400': element.status == 'draft',
            }"
          >
            {{ element.status }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    [pageSizeOptions]="[10, 20]"
    showFirstLastButtons
    aria-label="Select page of amc projects"
    class="!text-sm sm:!text-base"
  >
  </mat-paginator>
</div>
