<app-navbar [showHome]="false"></app-navbar>

<div class="flex min-h-[calc(100vh-4.5rem)] w-full items-center pt-[4.5rem]">
  <div class="w-full">
    <div class="w-full bg-gray-100 px-8 py-14">
      <div class="mb-6 flex w-full justify-center">
        <img src="/assets/verified.png" width="70px" alt="GreenCare Logo" />
      </div>
      <h1 class="my-6 text-center font-sans text-4xl font-semibold text-gray-700">
        Thank you for registering with GreenCare!
      </h1>
      <p class="my-2 text-center font-sans text-base font-light text-gray-700">
        Your registration is currently under verification, and it will be completed within 24 hours.
      </p>
      <p class="text-center font-sans text-base font-light text-gray-700">
        You will receive an email notification once the verification is complete.
      </p>
    </div>
    <div class="w-full p-8">
      <div class="flex w-full flex-col justify-center">
        <h6 class="w-full flex-row text-center font-sans text-base font-normal text-gray-700">
          Learn more about our services and offerings
        </h6>
        <div class="w-full flex-row">
          <div class="m-4 flex justify-center">
            <a
              role="button"
              mat-flat-button
              color="accent"
              href="https://www.oorjan.com"
              target="_blank"
            >
              <span class="text-white">Visit Us</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
