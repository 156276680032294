import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) {}

  static isServerError(errorCode: number) {
    let SERVER_ERROR_REGEX = /5\d{2}/;
    return SERVER_ERROR_REGEX.test(errorCode.toString());
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'DISMISS', {
      duration: 15000,
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authToken = this.authService.getAuthToken();
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Token ' + authToken,
        },
      });
    }

    // extend server response observable with logging
    return next.handle(req).pipe(
      tap({
        // Operation failed; error is an HttpErrorResponse
        error: ({ status, error: err }: HttpErrorResponse) => {
          if (status === 0) {
            this.openSnackBar('Please check your internet connection and try again.');
          } else if (status === 401 || status === 403) {
            if (err && err?.detail) this.openSnackBar(err.detail);
            if (status === 401) this.authService.invalidateAuth.next(true);
          } else if (AuthInterceptor.isServerError(status)) {
            this.openSnackBar('Cannot connect to servers. Please try again later.');
          }
        },
      })
    );
  }
}
