import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  hide = true;

  goToSignUp() {
    this.router.navigate(['/signup']);
  }

  signinForm = new FormGroup({
    phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
  });

  onSubmit() {
    const { phone_number } = this.signinForm.value;
    if (phone_number) {
      this.authService.requestOtp(phone_number).then(_ =>
        this.router.navigate([`/verify-otp/${phone_number}`], {
          queryParams: {
            login: 1,
          },
        })
      );
    }
  }

  get phone_number() {
    return this.signinForm.get('phone_number');
  }
}
