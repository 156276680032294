<div mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-secondary">Schedule Activity</h1>
  <mat-stepper class="no-header" linear #stepper>
    <!-- <mat-step [stepControl]="scheduleActivityForm_1">
      <form [formGroup]="scheduleActivityForm_1">
        <div class="inline-input">
          <label for="add-project-contractor">Site Name <sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline">
            <input id="add-project-size" matInput type="text" formControlName="site_name" />
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label for="add-project-tenure">Start & End Date<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-date-range-input id="add-project-tenure" [rangePicker]="amcTenureDatePicker">
              <input formControlName="start_date" matStartDate placeholder="Start Date" />
              <input formControlName="end_date" matEndDate placeholder="End Date" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="amcTenureDatePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #amcTenureDatePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>

      <div class="stepper-nav-container">
        <button matStepperNext type="button" class="next-btn">
          Next
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </mat-step> -->

    <mat-step [stepControl]="scheduleActivityForm_2">
      <form [formGroup]="scheduleActivityForm_2">
        <div formArrayName="activities">
          <div formGroupName="{{ i }}" *ngFor="let activity of activities.controls; let i = index">
            <div class="flex flex-col">
              <h3 class="text-lg font-semibold">Activity {{ i + 1 }}<sup>*</sup> :</h3>
              <div class="grid grid-cols-3 gap-x-4">
                <mat-form-field class="w-full" appearance="outline">
                  <mat-select
                    placeholder="Type of Activity"
                    id="add-project-offering"
                    formControlName="activity_type"
                  >
                    <mat-option *ngFor="let amcActivity of amcActivityType" [value]="amcActivity">{{
                      amcActivity
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full" appearance="outline">
                  <mat-select
                    placeholder="Contractor"
                    id="add-project-offering"
                    formControlName="contractor_org"
                  >
                    <mat-option *ngFor="let contractor of contractors" [value]="contractor.id">{{
                      contractor.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full" appearance="outline">
                  <input
                    placeholder="Date"
                    matInput
                    formControlName="scheduled_date"
                    [matDatepicker]="scheduledDatePicker"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="scheduledDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #scheduledDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="my-8 flex justify-center space-x-8">
        <button
          (click)="addActivity()"
          *ngIf="activities.controls.length == 1"
          type="button"
          class="mr-2 inline-flex items-center rounded-full bg-lima-400 p-2.5 text-center text-sm font-medium text-white hover:bg-lima-500 focus:outline-none focus:ring-4 focus:ring-lima-300"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          (click)="removeActivity()"
          *ngIf="activities.controls.length == 2"
          type="button"
          class="mr-2 inline-flex items-center rounded-full bg-red-400 p-2.5 text-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
      <div class="stepper-nav-container">
        <button
          (click)="createActivity()"
          [disabled]="!scheduleActivityForm_2.valid || isLoading"
          type="button"
          class="save-btn"
        >
          Save & Close
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
