import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
} from '@angular/router';
import { initFlowbite } from 'flowbite';
import { AuthService } from './core/auth/auth.service';
import { AppService } from './core/services/app.service';
import { UserProfileService } from './core/services/user-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'amc-platform';
  loader = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService,
    private userProfile: UserProfileService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  get showNavbar() {
    return this.appService.navbar;
  }

  handleNavbarState(event: NavigationEnd) {
    let state = event.url !== '/';
    for (let url of ['/login', '/signup', '/org-signup', '/registration-complete']) {
      if (event.url.startsWith(url)) {
        state = false;
        break;
      }
    }

    this.appService.showNavbar(this.userProfile.hasUserProfile() && state);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loader = true;
    }
    if (event instanceof NavigationEnd) {
      this.handleNavbarState(event);

      setTimeout(() => {
        this.loader = false;
      }, 1200);
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => {
        this.loader = true;
      }, 1200);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        this.loader = true;
      }, 1200);
    }
  }

  isLoggedIn() {
    return this.authService.isUserLoggedIn();
  }

  ngOnInit(): void {
    initFlowbite();
  }
}
