import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input({ required: false }) showHome: boolean = true;
  isOrgApproved: boolean = false;

  constructor(
    private userProfile: UserProfileService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    initFlowbite();
    this.isOrgApproved = this.userProfile.isOrgApproved();
  }

  getUserName() {
    return this.userProfile.user.name;
  }

  getRole() {
    return this.userProfile.user.role_type;
  }

  getOrgName() {
    return this.userProfile.org.name;
  }

  signOut() {
    this.authService.logOut();
  }

  goToMyOrganization() {
    this.router.navigate(['/my-organization']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }
}
