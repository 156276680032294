import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
})
export class RegistrationCompleteComponent {
  constructor(
    private userProfile: UserProfileService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // initFlowbite();
  }

  getUserName() {
    return this.userProfile.user.name;
  }

  signOut() {
    this.authService.logOut();
  }
}
