<mat-dialog-content>
  <div class="relative flex h-full w-full items-center justify-center">
    <button
      (click)="closeDialog()"
      class="absolute right-0 top-0 flex h-11 w-11 items-center justify-center rounded-full bg-black/10 hover:bg-black/20"
    >
      <mat-icon>close</mat-icon>
    </button>
    <div class="keen-slider zoom-out" #sliderRef>
      <div
        class="keen-slider__slide zoom-out__slide"
        *ngFor="let mediaUrl of data.media; let idx = index"
      >
        <div [ngStyle]="scaleStyle(idx)">
          <img *ngIf="isImageUrl(mediaUrl)" [src]="mediaUrl" />
          <video
            type="video/mp4"
            playsinline
            loop
            autoplay
            controls
            [muted]="true"
            *ngIf="isVideoUrl(mediaUrl)"
          >
            <source [src]="mediaUrl" />
          </video>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
