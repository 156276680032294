import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AMC_ACTIVITY_TYPE, AMC_TYPE } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { formatAmcDate } from 'src/app/core/utils';
import { DialogMode } from 'src/types';

@Component({
  selector: 'app-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
})
export class CreateProjectDialogComponent implements OnInit {
  greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return { required: true };
      }
      return parseFloat(control.value) > 0 ? null : { greaterThanZero: true };
    };
  }
  createProjectForm_1 = new FormGroup({
    project_owner: new FormControl('', Validators.required),
    owner_contact_details: new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      email: new FormControl('', [Validators.required, Validators.email]),
    }),
    project_name: new FormControl('', Validators.required),
    site_name: new FormControl('', Validators.required),
    isProjectNameSameAsOwner: new FormControl(false),
    site_contact_details: new FormGroup({
      phone_number: new FormControl('', [Validators.pattern('^[0-9]{10}$'), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    }),
    size: new FormControl('', [Validators.required, this.greaterThanZeroValidator()]),
    location: new FormGroup({
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')]),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
    }),
    discom: new FormControl('', Validators.required),
    grid_connection_type: new FormControl('', Validators.required),
    phase_type: new FormControl('', Validators.required),
    connection_type: new FormControl('', Validators.required),
    blocks: new FormArray([
      new FormGroup({
        block_name: new FormControl('', Validators.required),
        mounting_type: new FormControl('', Validators.required),
      }),
    ]),
    panels: new FormArray([
      new FormGroup({
        make: new FormControl('', Validators.required),
        wp_per_panel: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
        no_of_panels: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
      }),
    ]),
    inverters: new FormArray([
      new FormGroup({
        name: new FormControl('', Validators.required),
        size: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
        no_of_inverters: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
      }),
    ]),
    commissioning_date: new FormControl<Date | null>(null, Validators.required),
  });

  createProjectForm_2 = new FormGroup({
    start_date: new FormControl<Date | null>(null, Validators.required),
    end_date: new FormControl<Date | null>(null, Validators.required),
    amc_contract_type: new FormControl('', Validators.required),
    activities: new FormArray([]),
    amc_fee: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
    contractor_org: new FormControl(0, Validators.required),
    cost_of_service: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
  });

  phaseType = ['One Phase', 'Three Phase'];
  OfferingType = ['On-Grid', 'Off-Grid'];
  mountingType = ['Metal Roof', 'RCC Roof', 'Mangalore Tile', 'Land Mount', 'Cement Sheet'];
  connectionType = ['Residential', 'Society', 'Commercial', 'Industrial', 'Government', 'Trust'];
  discom: string[] = [];
  amcTypes: string[] = AMC_TYPE;
  amcSpecialTypes = AMC_ACTIVITY_TYPE.slice(-2);
  isLoading = false;

  constructor(
    private amcProjectService: AmcProjectService,
    public dialogRef: MatDialogRef<CreateProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId?: string; mode: DialogMode }
  ) {
    this.pincode?.valueChanges.subscribe(value => {
      if (this.pincode?.valid) {
        this.amcProjectService
          .fetchPincodeDetails(value as string)
          .then(({ electricity_providers, city, state }) => {
            this.discom = electricity_providers;
            this.city?.setValue(city);
            this.state?.setValue(state);
          });
      }
    });

    this.createProjectForm_1.controls['project_owner'].valueChanges.subscribe(value => {
      if (this.isProjectNameSameAsOwner && value) {
        this.createProjectForm_1.controls['project_name'].setValue(value);
      }
    });

    this.createProjectForm_1.controls['isProjectNameSameAsOwner'].valueChanges.subscribe(value => {
      if (value) {
        this.createProjectForm_1.controls['project_name'].setValue(
          this.createProjectForm_1.controls['project_owner'].value
        );
      }
    });

    this.panels.controls.map(control =>
      control.controls.wp_per_panel.valueChanges.subscribe(value => {
        if (this.projectSizeInWp && value) {
          control.controls.no_of_panels.setValue(Math.round(this.projectSizeInWp / value));
        }
      })
    );

    this.amc_contract_type?.valueChanges.subscribe(value => {
      this.amc_activities.clear();
      if (value && this.amcSpecialTypes.includes(value)) {
        if (value === 'Preventive with cleaning') {
          this.amc_activities.push(
            new FormGroup({
              activity_type: new FormControl('Preventive AMC', Validators.required),
              visits: new FormControl(0, Validators.required),
            })
          );
        } else {
          this.amc_activities.push(
            new FormGroup({
              activity_type: new FormControl('Comprehensive AMC', Validators.required),
              visits: new FormControl(0, Validators.required),
            })
          );
        }

        this.amc_activities.push(
          new FormGroup({
            activity_type: new FormControl('Cleaning only', Validators.required),
            visits: new FormControl(0, Validators.required),
          })
        );
      } else {
        this.amc_activities.push(
          new FormGroup({
            activity_type: new FormControl(value, Validators.required),
            visits: new FormControl(0, Validators.required),
          })
        );
      }
    });
  }
  ngOnInit(): void {
    if (this.data.mode !== 'create') {
      const found = this.amcProjectService.getProjects().find(i => i.id === this.data.projectId);

      if (found) {
        this.createProjectForm_1.controls.blocks.clear();
        this.createProjectForm_1.controls.panels.clear();
        this.createProjectForm_1.controls.inverters.clear();

        this.createProjectForm_1.setValue({
          project_owner: found.project_owner,
          project_name: found.project_name,
          owner_contact_details: {
            phone_number: found.project_details.owner_contact_details.phone_number.slice(3),
            email: found.project_details.owner_contact_details.email,
          },
          site_name: found.site_name,
          isProjectNameSameAsOwner: found.project_owner === found.project_name,
          site_contact_details: {
            email: found.project_details.site_contact_details.email,
            phone_number: found.project_details.site_contact_details.phone_number.slice(3),
          },
          size: found.project_details.size.toString(),
          location: found.project_details.location,
          discom: found.project_details.discom,
          grid_connection_type: found.project_details.grid_connection_type,
          phase_type: found.project_details.phase_type,
          connection_type: found.project_details.connection_type,
          blocks: [],
          panels: [],
          inverters: [],
          commissioning_date: found.project_details.commissioning_date
            ? new Date(found.project_details.commissioning_date)
            : null,
        });

        if (found.start_date)
          this.createProjectForm_2.controls.start_date.setValue(new Date(found.start_date));
        if (found.end_date)
          this.createProjectForm_2.controls.end_date.setValue(new Date(found.end_date));
        this.createProjectForm_2.controls.amc_contract_type.setValue(found.amc_contract_type);
        this.createProjectForm_2.controls.amc_fee.setValue(found.amc_fee);
        if (found.contractor_org)
          this.createProjectForm_2.controls.contractor_org.setValue(found.contractor_org.id);
        this.createProjectForm_2.controls.cost_of_service.setValue(found.cost_of_service);
        this.createProjectForm_2.controls.activities.clear();

        for (let panel of found.project_details.panels) {
          const newPanel = new FormGroup({
            make: new FormControl(panel.make, Validators.required),
            wp_per_panel: new FormControl(panel.wp_per_panel, Validators.required),
            no_of_panels: new FormControl(panel.no_of_panels, Validators.required),
          });

          newPanel.controls['wp_per_panel'].valueChanges.subscribe(value => {
            if (this.projectSizeInWp && value) {
              newPanel.controls.no_of_panels.setValue(Math.round(this.projectSizeInWp / value));
            }
          });

          this.panels.push(newPanel);
        }

        for (let block of found.project_details.blocks) {
          this.createProjectForm_1.controls.blocks.push(
            new FormGroup({
              block_name: new FormControl(block.block_name),
              mounting_type: new FormControl(block.mounting_type),
            })
          );
        }

        for (let inverter of found.project_details.inverters) {
          this.createProjectForm_1.controls.inverters.push(
            new FormGroup({
              name: new FormControl(inverter.name),
              no_of_inverters: new FormControl(inverter.no_of_inverters),
              size: new FormControl(inverter.size),
            })
          );
        }
      }

      if (this.data.mode === 'view') {
        this.createProjectForm_1.disable();
        this.createProjectForm_2.disable();
      }
    }
  }

  get amc_activities() {
    return this.createProjectForm_2.controls['activities'] as any as FormArray;
  }

  get location() {
    return this.createProjectForm_1.controls['location'];
  }

  get amc_contract_type() {
    return this.createProjectForm_2.controls['amc_contract_type'];
  }

  get pincode() {
    return this.location.get('pincode');
  }

  get city() {
    return this.location.get('city');
  }

  get state() {
    return this.location.get('state');
  }

  get contractors() {
    return this.amcProjectService.contractors;
  }

  onSubmit() {
    console.log();
  }

  get projectSizeInWp() {
    const value = this.createProjectForm_1.value.size;
    return value ? parseFloat(value) * 1000 : 0;
  }

  get isProjectNameSameAsOwner() {
    return this.createProjectForm_1.controls['isProjectNameSameAsOwner'].value as boolean;
  }

  get panels() {
    return this.createProjectForm_1.controls['panels'];
  }

  get blocks() {
    return this.createProjectForm_1.controls['blocks'] as FormArray;
  }

  get inverters() {
    return this.createProjectForm_1.controls['inverters'] as FormArray;
  }

  addFormArray(name: string) {
    if (name === 'blocks') {
      const block = new FormGroup({
        block_name: new FormControl('', Validators.required),
        mounting_type: new FormControl('', Validators.required),
      });
      this.blocks.push(block);
    } else if (name === 'panels') {
      const panel = new FormGroup({
        make: new FormControl('', Validators.required),
        wp_per_panel: new FormControl(0, Validators.required),
        no_of_panels: new FormControl(0, Validators.required),
      });

      panel.controls['wp_per_panel'].valueChanges.subscribe(value => {
        if (this.projectSizeInWp && value) {
          panel.controls.no_of_panels.setValue(Math.round(this.projectSizeInWp / value));
        }
      });

      this.panels.push(panel);
    } else if (name === 'inverters') {
      const inverter = new FormGroup({
        name: new FormControl('', Validators.required),
        no_of_inverters: new FormControl(0, Validators.required),
        size: new FormControl(0, Validators.required),
      });
      this.inverters.push(inverter);
    }
  }

  removeFormArray(name: string) {
    if (name === 'blocks') {
      this.blocks.removeAt(-1);
    } else if (name === 'panels') {
      this.panels.removeAt(-1);
    } else if (name === 'inverters') {
      this.inverters.removeAt(-1);
    }
  }

  onDebug() {
    console.log(
      JSON.stringify(
        {
          'form-1': {
            isValid: this.createProjectForm_1.valid,
            value: this.createProjectForm_1.value,
          },
          'form-2': {
            isValid: this.createProjectForm_2.valid,
            value: this.createProjectForm_2.value,
          },
        },
        undefined,
        4
      )
    );
  }

  getTotalWp(index: number) {
    const panel = this.panels.at(index) as any;
    if (panel) {
      const { wp_per_panel, no_of_panels } = panel.value;
      return wp_per_panel * no_of_panels;
    }

    return 0;
  }

  isTotalWpWrong(index: number) {
    let projectSize = this.projectSizeInWp;
    const totalWp = this.getTotalWp(index);

    if (projectSize > 0 && totalWp > 0) {
      const percentage = 8;
      const variation = (projectSize * percentage) / 100;

      if (Math.abs(totalWp - projectSize) > variation) return true;
    }

    return false;
  }

  getForm1Data() {
    const {
      project_name,
      project_owner,
      site_name,
      owner_contact_details,
      site_contact_details,
      location,
      discom,
      grid_connection_type,
      phase_type,
      connection_type,
      blocks,
      size,
      panels,
      inverters,
      commissioning_date,
    } = this.createProjectForm_1.value;

    const createProjectBody: any = {
      project_owner,
      project_name,
      site_name,
      project_details: {
        owner_contact_details: {
          email: owner_contact_details?.email,
          phone_number: owner_contact_details?.phone_number
            ? `+91${owner_contact_details?.phone_number}`
            : '',
        },
        site_contact_details: {
          email: site_contact_details?.email,
          phone_number: site_contact_details?.phone_number
            ? `+91${site_contact_details?.phone_number}`
            : '',
        },
        location,
        discom,
        grid_connection_type,
        phase_type,
        connection_type,
        blocks,
        size: size ? parseFloat(size) : 0,
        panels,
        inverters,
        commissioning_date: formatAmcDate(commissioning_date),
      },
    };
    return createProjectBody;
  }

  saveAndClose() {
    this.isLoading = true;
    const data = this.getForm1Data();

    this.amcProjectService
      .createAmcProject(data)
      .then(() => {
        this.isLoading = false;
        this.dialogRef.close({ success: true });
      })
      .catch(() => (this.isLoading = false));
  }

  createProject() {
    if (this.createProjectForm_1.valid && this.createProjectForm_2.valid) {
      this.isLoading = true;
      const data1 = this.getForm1Data();
      const {
        activities,
        amc_contract_type,
        amc_fee,
        contractor_org,
        cost_of_service,
        end_date,
        start_date,
      } = this.createProjectForm_2.value;
      const mergedFormData = {
        ...data1,
        start_date: formatAmcDate(start_date),
        end_date: formatAmcDate(end_date),
        activities,
        amc_contract_type,
        amc_fee,
        contractor_org,
        cost_of_service,
      };

      // console.log(mergedFormData);

      if (this.data.mode === 'create') {
        this.amcProjectService
          .createAmcProject(mergedFormData)
          .then(() => {
            this.isLoading = false;
            this.dialogRef.close({ success: true });
          })
          .catch(() => (this.isLoading = false));
      }
      if (this.data.mode === 'edit' && this.data.projectId) {
        this.amcProjectService
          .editAmcProject(this.data.projectId, mergedFormData)
          .then(() => {
            this.isLoading = false;
            this.dialogRef.close({ success: true });
          })
          .catch(() => (this.isLoading = false));
      }
    }
  }

  isValidtoSave() {
    const fieldsToValidate = [
      'project_owner',
      'owner_contact_details',
      'project_name',
      'site_name',
      'isProjectNameSameAsOwner',
      'site_contact_details',
      'size',
    ];

    for (let field of fieldsToValidate) {
      if (
        !this.createProjectForm_1.controls[field as keyof typeof this.createProjectForm_1.controls]
          .valid
      ) {
        return false;
      }
    }
    return true;
  }
}
