import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import KeenSlider, { KeenSliderInstance, TrackDetails } from 'keen-slider';
import { isImageUrl, isVideoUrl } from 'src/app/core/utils';

@Component({
  selector: 'app-media-preview-dialog',
  templateUrl: './media-preview-dialog.component.html',
  styleUrls: [
    '../../../../node_modules/keen-slider/keen-slider.min.css',
    './media-preview-dialog.component.scss',
  ],
})
export class MediaPreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MediaPreviewDialogComponent>,
    // private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { media: string[]; selectedIndex: number }
  ) {}

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  details: TrackDetails | undefined;
  slider: KeenSliderInstance;

  isImageUrl = isImageUrl;
  isVideoUrl = isVideoUrl;

  scaleStyle(idx: number) {
    if (!this.details) return {};
    const slide = this.details.slides[idx];
    const scale_size = 0.7;
    const scale = 1 - (scale_size - scale_size * slide.portion);
    return {
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        loop: true,
        detailsChanged: s => {
          this.details = s.track.details;
        },
        initial: this.data.selectedIndex,
      });
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
