import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

function isValidURL(url: string) {
  try {
    new URL(url.startsWith('http') ? url : `https://${url}`);
    return true;
  } catch (e) {
    return false;
  }
}

export const websiteValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value === '') return null;
  return isValidURL(control.value) ? null : { invalidUrl: true };
};
