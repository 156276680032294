import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessPageComponent } from './pages/business-page/business-page.component';
import { ContractorPageComponent } from './pages/contractor-page/contractor-page.component';

import { authGuard } from './core/auth/auth.guard';
import { authResolver } from './core/auth/auth.resolver';
import { BusinessPageDetailsComponent } from './pages/business-page-details/business-page-details.component';
import { ContractorPageDetailsComponent } from './pages/contractor-page-details/contractor-page-details.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MyOrganizationPageComponent } from './pages/my-organization-page/my-organization-page.component';
import { OrgSignupPageComponent } from './pages/org-signup-page/org-signup-page.component';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { RegistrationCompleteComponent } from './pages/registration-complete/registration-complete.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent, resolve: [authResolver] },
  { path: 'verify-otp/:phone_number', component: OtpVerifyComponent, resolve: [authResolver] },
  { path: 'signup', component: SignupPageComponent, resolve: [authResolver] },
  {
    path: 'registration-complete',
    component: RegistrationCompleteComponent,
    canActivate: [authGuard],
  },
  { path: 'org-signup', component: OrgSignupPageComponent, canActivate: [authGuard] },
  { path: 'business', component: BusinessPageComponent, canActivate: [authGuard] },
  { path: 'business/:id', component: BusinessPageDetailsComponent, canActivate: [authGuard] },
  { path: 'contractor', component: ContractorPageComponent, canActivate: [authGuard] },
  { path: 'contractor/:id', component: ContractorPageDetailsComponent, canActivate: [authGuard] },
  { path: 'my-organization', component: MyOrganizationPageComponent, canActivate: [authGuard] },
  { path: '', component: HomePageComponent, canActivate: [authGuard] },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
