import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'dateFormatter',
})
export class DateFormatterPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return value ? format(new Date(value), 'dd MMM, yyyy') : 'N/A';
  }
}
