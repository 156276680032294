<div class="table-card mt-10 flex flex-col p-4">
  <div class="mb-2 mt-3 flex items-center justify-between px-4">
    <h3 class="text-2xl font-medium">My Projects</h3>
    <!-- <button
      (click)="openDialog('0ms', '0ms')"
      type="button"
      class="inline-flex items-center rounded-full bg-primary px-4 py-2 text-center text-base font-medium text-white hover:bg-primary/90 focus:outline-none focus:ring-4 focus:ring-primary/50"
    >
      <mat-icon class="mr-1">add</mat-icon>
      Project
    </button> -->
  </div>

  <div class="px-2 pb-4 pt-8">
    <form [formGroup]="filterForm" class="flex w-full items-center space-x-10">
      <mat-form-field floatLabel="auto" class="w-96">
        <mat-label>Search by (Name, Location)</mat-label>
        <input matInput type="text" formControlName="search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <!-- <mat-form-field>
        <mat-label>Project Owner</mat-label>

        <mat-select formControlName="project_owner">
          <mat-option value="all">All</mat-option>
          <mat-option
            // *ngFor="let projectOwner of projectOwnerFilterOptions"
            [value]="projectOwner.value"
            >{{ projectOwner.name }}</mat-option
          >
        </mat-select>
      </mat-form-field> -->

      <mat-form-field>
        <mat-label>Location (City, State)</mat-label>
        <mat-select formControlName="location">
          <mat-option value="all">All</mat-option>
          <mat-option *ngFor="let location of projectLocationFilterOptions" [value]="location">{{
            location
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="all">All</mat-option>
          <mat-option value="active">ACTIVE</mat-option>
          <mat-option value="expired">EXPIRED</mat-option>
        </mat-select>
      </mat-form-field> -->
    </form>
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="project_name">
      <th mat-header-cell *matHeaderCellDef>Project Name</th>
      <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
        {{ element.projectName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
        {{ element.location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="project_owner">
      <th mat-header-cell *matHeaderCellDef>Business</th>
      <td (click)="openAmcDetails(element.id)" mat-cell *matCellDef="let element">
        {{ element.business }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20]"
    showFirstLastButtons
    aria-label="Select page of amc projects"
  >
  </mat-paginator>
</div>
